import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { FormBuilder, Validators } from '@angular/forms'
import { Select, Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { Branch } from '../../interfaces/branch.interface'
import { Path } from '../../app-routing.config'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AmplitudeService } from '../../entities/amplitude.service'
import { takeUntil } from 'rxjs/operators'
import { ValidateAppointment } from '../../interfaces/appointment.interface'
import { AppointmentsApiService } from '../../api-service/appointments/appointments-api.service'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  newAppointmentForm = this.fb.group({
    employee: [null, [Validators.required]],
    date: [null, [Validators.required]],
    totalPrice: [null, [Validators.required]],
    totalDuration: [null, [Validators.required]],
    services: [[], [Validators.required]],
    description: [null]
  });
  onDestroy$ = new Subject<void>();
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  hasError: boolean = false;
  errorCode: number = null;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private datePipe: DatePipe,
    private appointmentsApiService: AppointmentsApiService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.onOpenAppointmentCreation();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenAppointmentCreation() : void {
    this.amplitudeService.trackEvent(
      'widget_appointment_creation_started',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
      });
  }

  goToClientSetUp() {
    this.router.navigate([this.branchSlug, Path.CONFIRMATION], {
      queryParamsHandling: 'merge',
    });
  }

  deleteComment() {
    this.newAppointmentForm.patchValue({
      description: null
    });
  }

  validateAppointmentOnSubmit() {
    const formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);

    const arrayOfIds = formValue.services.map(service => service.id);
    const shortenedTime = formValue.date.timeSlot.split(":").slice(0, 2).join(":");
    const shortenedDate = this.datePipe.transform(formValue.date.date, 'yyyy-MM-dd');
    const date = new Date(`${shortenedDate} ${shortenedTime}`);
    const isoFormatDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    const body: ValidateAppointment = {
      branch_id: this.branchId,
      company_id: this.companyId,
      employee_id: formValue.employee.id,
      start_at: isoFormatDate,
      services: arrayOfIds,
      price: formValue.totalPrice,
      duration: formValue.totalDuration,
      description: formValue.description
    };

    this.appointmentsApiService.validateAppointment(body).subscribe(
      {
        next: ( appointment ) => {
          this.goToClientSetUp();
        },
        error: ({error}) => {
          this.hasError = true;
          this.errorCode = error.detail.error_code;
        }
      }
    )
  }

  onSubmit() {
    if (this.newAppointmentForm.valid) {
      this.validateAppointmentOnSubmit();
    }
  }

  getSanitizedUrl(url: string) {
    if (!url.startsWith('http')) {
      return 'https://' + url;
    }
    return url;
  }

  goToHomePage() {
    this.hasError = false;
  }
}
