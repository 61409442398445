<ng-container *ngIf="showComments; else noComments">
  <app-input
    [ngClass]="{'edit-mode': mode === CommentMode.Edit}"
    [label]="mode === CommentMode.Edit ? 'Коментар' : 'Коментар до запису'"
    [formControl]="control"
    [isTextarea]="true"
    [showActionButton]="true"
    [actionButtonTitle]="mode === CommentMode.Edit ? 'Зберегти' : 'Видалити'"
    (onActionButtonClick)="actionButtonClick()"
  ></app-input>
</ng-container>

<ng-template #noComments>
  <ng-container *ngIf="mode === CommentMode.Edit && control?.value; else default">
    <label class="comment-label">Коментар</label>
    <div
      class="comment"
      [ngClass]="{
        'edit-mode': mode === CommentMode.Edit && control?.value,
        'edit-disabled': !isEditable
      }"
      (click)="editComment()">
      <div class="text">
        {{ control.value || "Коментар відсутній" }}
      </div>
      <i *ngIf="isEditable" class="icon-pencil-create"></i>
    </div>
  </ng-container>

  <ng-template #default>
    <button
      *ngIf="isEditable"
      class="outline -no-border add-comment"
      (click)="showComments = true">
      <i class="icon-plus"></i> Додати коментар
    </button>
  </ng-template>
</ng-template>
