import { Component, OnInit, EventEmitter, Output, Injector, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../input/input.component';
import { FormControlValueAccessorConnector } from '../form-control-value-accessor-connector';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum CommentMode {
  Default = 'default',
  Edit = 'edit'
}

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddCommentComponent),
      multi: true
    }
  ],
})
export class AddCommentComponent extends FormControlValueAccessorConnector implements OnInit {
  @Input() mode: string = CommentMode.Default;
  @Input() isEditable: boolean = true;
  @Input() showComments: boolean = false;
  CommentMode = CommentMode;

  @Output() onAction = new EventEmitter<string>();

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  actionButtonClick() {
    this.showComments = false;
    this.onAction.emit(this.control.value);
  }

  editComment() {
    this.showComments = true;

  }

}
