import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { NgxsModule } from '@ngxs/store'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { environment } from '@env/environment'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSidenavModule } from '@angular/material/sidenav'
import { HttpClientModule } from '@angular/common/http'
import { MatMenuModule } from '@angular/material/menu'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { PipesModule } from './pipes/pipes.module'
import { LoadingState } from './state/loading/loading.state'
import { ToastrModule } from 'ngx-toastr'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSelectModule } from '@angular/material/select'
import { IsLoadingSpinnerComponent } from './shared/is-loading-spinner/is-loading-spinner.component'
import { AvatarComponent } from './shared/avatar/avatar.component'
import { FullWindowModalComponent } from './shared/full-window-modal/full-window-modal.component'
import { NgxsFormPluginModule } from '@ngxs/form-plugin'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateWebpackLoader } from './translation/translateWebpackLoader'
import { DeviceDetectorService } from './entities/device-detector.service'
import { DatePipe, registerLocaleData } from '@angular/common'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import localeUk from '@angular/common/locales/uk'
import { HomeComponent } from './components/home/home.component'
import { ServicesComponent } from './components/services/services.component'
import { EmployeeComponent } from './components/employee/employee.component'
import { DateComponent } from './components/date/date.component'
import { ConfirmationComponent } from './components/confirmation/confirmation.component'
import { SuccessComponent } from './components/success/success.component'
import { FormDataCardComponent } from './shared/form-data-card/form-data-card.component'
import { EmployeeDataCardComponent } from './shared/form-data-card/components/employee-data-card/employee-data-card.component'
import { ServicesDataCardComponent } from './shared/form-data-card/components/services-data-card/services-data-card.component'
import { DateDataCardComponent } from './shared/form-data-card/components/date-data-card/date-data-card.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ToggleButtonComponent } from './shared/toggle-button/toggle-button.component'
import { AnimatedContinueButton } from './shared/animated-continue-button/animated-continue-button'
import { ListItemsWrapperComponent } from './shared/list-items/list-items-wrapper.component'
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker'
import { WeekViewComponent } from './shared/week-view/week-view.component'
import { ClientFormComponent } from './components/client-form/client-form.component'
import { ShortInfoCardComponent } from './shared/short-info-card/short-info-card.component'
import { DirectiveModule } from './directives/directive.module'
import { AppState } from './state/app.state'
import { EmptyStateComponent } from './shared/empty-state/empty-state.component'
import { InputComponent } from './shared/input/input.component'
import { ErrorPageComponent } from './shared/error-page/error-page.component'
import { AddCommentComponent } from './shared/add-comment/add-comment.component'

registerLocaleData(localeUk)

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    EmployeeComponent,
    DateComponent,
    ConfirmationComponent,
    SuccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    AvatarComponent,
    IsLoadingSpinnerComponent,
    NgScrollbarModule,
    MatExpansionModule,
    MatSelectModule,
    HttpClientModule,
    MatDialogModule,
    MatSidenavModule,
    FullWindowModalComponent,
    MatMenuModule,
    PipesModule,
    MatIconModule,
    NgxsFormPluginModule.forRoot(),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        iconClasses: {
          error: 'toast-error',
          info: 'toast-info',
          success: 'toast-success icon-checked-circle',
          warning: 'toast-warning',
        },
      },
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'uk',
      loader: {
        provide: TranslateLoader,
        useFactory: (translateFactory),
      },
    }),
    NgxsModule.forRoot([
      LoadingState,
      AppState,
    ], {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false,
      },
    }),
    FormDataCardComponent,
    EmployeeDataCardComponent,
    ServicesDataCardComponent,
    DateDataCardComponent,
    ReactiveFormsModule,
    ToggleButtonComponent,
    AnimatedContinueButton,
    ListItemsWrapperComponent,
    AngularMyDatePickerModule,
    WeekViewComponent,
    ClientFormComponent,
    ShortInfoCardComponent,
    FormsModule,
    DirectiveModule,
    EmptyStateComponent,
    InputComponent,
    ErrorPageComponent,
    AddCommentComponent,
  ],
  providers: [
    DatePipe,
    DeviceDetectorService,
    provideEnvironmentNgxMask(),
    {
      provide: LOCALE_ID,
      useValue: 'uk',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function translateFactory() {
  return new TranslateWebpackLoader()
}
